import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { API } from 'aws-amplify';
import { useForm } from "react-hook-form";

import Seo from '../components/seo';

import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

interface FormContactParms {
    eaddress: string,
    smessage: string,
}

const ContactPage = () => {
    const { enqueueSnackbar } = useSnackbar();

    // form states
    const formDefaultVal: FormContactParms = {
        eaddress: '',
        smessage: 'please contact me',
    }

    const { register, handleSubmit, reset, formState } = useForm({defaultValues: formDefaultVal});
    const { isDirty, errors } = formState;

    const onSubmit = async (data: FormContactParms) => {
        console.log('message data', data);
        const myParms = {
          body: {
            'subject': 'website - contact',
            'message': `${data.smessage} ${data.eaddress}`,
          },
          headers: {}, // OPTIONAL
        };

        try {
            await API.post('apwbench-pub', '/mailer', myParms)
            reset();
            enqueueSnackbar(`Thank you for contacting us!`,
              {variant: 'success', anchorOrigin: {vertical: 'bottom', horizontal: 'right'}} );
        } catch (apiresult) {
            console.log("got sendmail error", apiresult);
            enqueueSnackbar(`message failed`, {variant: 'error'} );
        }
    };


    return (
      <>
        <Seo title="Contact" />
        <Paper sx={{padding: '0.5em 0.4em 1em'}}>
        <Grid container spacing={0}>
          <Grid item xs={4} md={3}>
            <Box ml={2}>
              <StaticImage alt="" width={160} loading="eager" aria-labelledby="site-name" src="../images/wernerdigital-hosted.png" />
            </Box>
          </Grid>
          <Grid item xs={8} md={9}>
            <Box mt={6} ml={1} mb={2}>
              <h1 id="site-name">Contact Us</h1>
              <Grid container spacing={0}>
                <Grid item xs={12} md={2}>
                  Sales
                </Grid>
                <Grid item xs={12} md={10}>
                  &nbsp;-&nbsp;sales&#64;wernerdigital&#46;com
                </Grid>
                <Grid item xs={12} md={2}>
                  Support
                </Grid>
                <Grid item xs={12} md={10}>
                  &nbsp;-&nbsp;support&#64;wernerdigital&#46;com<br />
                </Grid>
                <Grid item xs={12} md={2}>
                  Beta programs
                </Grid>
                <Grid item xs={12} md={10}>
                  &nbsp;-&nbsp;beta&#64;wernerdigital&#46;com<br />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Box display='flex' flexWrap='wrap' justifyContent='space-around'>
        <Card className='grad' sx={{padding: '0.5rem', display:'flex', alignItems: 'center'}}>
          Werner Digital Technology, Inc <br />
          484 East Carmel Dr # 151 <br />
          Carmel Indiana 46032 <br />
        </Card>


        <Card sx={{margin: '0.5rem', padding: '0.5rem', maxWidth: '460px'}}>
        Or use our webform:
        <form key="apply" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={3} style={{textAlign: "right"}}>
              <label htmlFor="eaddress">Your Email</label>
            </Grid>
            <Grid item xs={9}>
              <input type="text" size={20} id="eaddress"
                {...register('eaddress', { required: true, pattern: /\S+@\S+\.\S+/, maxLength:35 })}
                aria-invalid={errors.eaddress ? "true" : "false"}
              />
            </Grid>
            <Grid item xs={12}>
              {errors.eaddress && <span role="alert" style={{color: "#B00020"}}>&nbsp;&nbsp;valid email address required</span> }
            </Grid>
            <Grid item xs={3} style={{textAlign: "right"}}>
              <label htmlFor="smessage">Message </label>
            </Grid>
            <Grid item xs={9}>
              <input type="text" size={24} id="smessage"
                {...register('smessage', { required: true, pattern: /\S+/, maxLength:35 })}
                aria-invalid={errors.smessage ? "true" : "false"}
              />
            </Grid>
            <Grid item xs={12}>
              {errors.smessage && <span role="alert" style={{color: "#B00020"}}>&nbsp;&nbsp;must be less than 35 char and non-blank</span> }
            </Grid>
            <Grid item xs={12}>
              <Button size="small" variant="contained" onClick={() => reset()} disabled={!isDirty}>Reset</Button>
              &nbsp;<Button size="small" variant="contained" color="primary" type="submit" disabled={!isDirty}> Send</Button>
            </Grid>
          </Grid>
        </form>
        </Card>

        </Box>
        </Paper>
      </>
)}

export default ContactPage
